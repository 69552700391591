import React from 'react';
import ScrollTop from './ScrollTop';
import contactImage from '../assets/contactImage.png'
import linkedin from '../assets/linkedin.png'
import instagram from '../assets/instagram.png'

const Contact = () => {
  return (
    <section id="Contact" className="py-6 px-4 self-center w-[80vw]">
      <ScrollTop />
      <div className="container mx-auto flex flex-col sm:flex-row text-center sm:text-left">
        <img src={contactImage} alt="Keagan Holmes" className="w-32 h-32 m-auto sm:left-4 sm:ml-8 sm:mr-28 rounded-full"/>
        <div>
          <h2 className="text-2xl font-bold mb-4">Contact</h2>
          <p>The best way to contact me is per email, at <a href="mailto:contact@keagan.info"><i>contact@keagan.info</i></a>.</p>
          <br/>
          <p>You can also find me on social media:</p>
          <div className="my-5 flex flex-row justify-center sm:justify-start space-x-8 sm:space-x-4">  
            <a href={"https://www.instagram.com/keagan.skye/"}>
                <img src={instagram} alt="Instagram logo" className="w-5 h-5"/>
            </a>

            <a href={"https://www.linkedin.com/in/keagan-holmes/"}>
              <img src={linkedin} alt="LinkedIn logo" className="w-5 h-5"/>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
