import React from 'react';
import Portrait from '../assets/PortraitCompressed.jpg';  // replace with actual path
import ScrollTop from './ScrollTop';

const About = () => {
  return (
    <section id="About" className="py-6 px-4 self-center w-[80vw]">
      <ScrollTop />
      <div className="container mx-auto flex flex-col sm:flex-row items-center">
        <img src={Portrait} alt="Keagan Holmes" className="w-48 h-48 rounded-full sm:mr-20"/>
        <div className="w-full text-center sm:text-left">
          <h2 className="text-2xl font-bold mb-2">About Me</h2>
          <p className="text-lg">Welcome to my personal website!</p>
          
          <p className="text-lg my-2">I'm a recent university graduate with a Bachelor's of Science in Robotics and Intelligent Systems from <a href="https://www.jacobs-university.de/">Jacobs University Bremen</a>.</p>
          
          <p className="text-lg my-2">I went to high school in San Diego, California and am now living in Germany. I'm still deciding if I want to pursue a Masters or take a gap year and work. I'm excited for these next steps as I'm sure whatever I end up doing be interesting.</p>
        </div>
      </div>
    </section>
  );
}

export default About;
