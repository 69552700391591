import React from 'react';
import defaultProjectImage from '../../assets/defaultProjectImage.png'


const Project = ({title, content, img, imgAltText, linkToProject, isOnLeftSide=true}) => {
  return (
    <div className={"container mx-auto flex flex-col sm:flex-row text-center " + (isOnLeftSide ? "sm:left-0 sm:text-left" : "sm:right-0 sm:text-right")}>
      <img src={img ? img : defaultProjectImage} alt={imgAltText ? imgAltText : "A poorly drawn image of a puzzle piece."} className={"m-auto " + (isOnLeftSide ? "sm:left-4 sm:ml-8 sm:mr-28" : "right-8 ml-28") + " w-32 h-32 rounded-full"}/>
      <div>
        <h3 className="text-lg font-bold mb-4">{title}</h3>
        {content}
        {linkToProject ? <a href={linkToProject} target="_blank" rel="noreferrer"><i>View this project online</i></a> : ""}
        <br/>
      </div>
    </div>
  );
}

export default Project;