import React from 'react';
import ScrollTop from './ScrollTop';
import photosImage from '../assets/photosImage.png'

const Photos = () => {
  return (
    <section id="Photos" className="py-6 px-4 self-center w-[80vw]">
      <ScrollTop />
      <div className="container mx-auto flex flex-col sm:flex-row text-center sm:text-left">
        <img src={photosImage} alt="Keagan Holmes" className="w-32 h-32 m-auto sm:left-4 sm:ml-8 sm:mr-28 rounded-full"/>
        <div>
          <h2 className="text-2xl font-bold mb-4">Photos</h2>
          <p>I've been getting more into photography lately - here are some of my favorites:</p>

          <br/>

          <div className="flex flex-col sm:flex-row justify-around">
            <p>...</p>
            <p>In</p>
            <p>Progress</p>
            <p>...</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Photos;
