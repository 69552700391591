import React from 'react';
// import {useState} from 'react'
import ScrollTop from './ScrollTop';
import Project from './templates/ProjectTemplate';


const Projects = () => {
  // const [browserZoomLevel, setBrowserZoomLevel] = useState(100)
  
  // window.addEventListener('resize', () => {
  //   setBrowserZoomLevel(window.devicePixelRatio * 100)
  // })
  
  return (
    <section id="Projects" className="py-6 px-4 mx-auto self-center w-[80vw]">
      <ScrollTop />
      
      <div className="container mx-auto flex flex-col sm:flex-row">
        <div className="w-32 m-auto sm:left-4 sm:ml-8 sm:mr-28" />
        <h2 className="text-2xl font-bold mb-2 text-center sm:text-left">{"Projects"}</h2>
      </div>

      <div className="flex flex-col space-y-10">
        <Project 
          title="Bachelor's Thesis" 
          content={<p>My bachelor's thesis was titled Visual Gesture Recognition and Applications in Smartwatches and involved thoroughly researching the field of gesture recognition and developing software. 
            I wrote code in Python which allowed for seamless data collection and processing, following by neural network training and data analysis. 
            The project was overall quite a success, though I would have of course liked to add some more content if not for the significant time constraint.</p>}
          linkToProject={"https://github.com/keaganchs/Visual-Gesture-Recognition"}
          />

        <Project
          title="Project Carbdown"
          content={<p>I was involved in a for-credit community impact project at my university called <i>Project Carbdown</i> in my fifth and sixth semesters.
            The project aimed to combat global warming through a process known as <a href="https://en.wikipedia.org/wiki/Enhanced_weathering" target="_blank" rel="noreferrer">enhanced weathering</a>.
            I was personally involved in sensor development, which would allow for verification of the drawdown of CO2 from the atmosphere and allowcation of carbon credits; as well as web development for raising awareness of the project.
            This project was done in part in affiliation with the <a href="https://www.carbon-drawdown.de/home-en" target="_blank" rel="noreferrer">Carbon Drawdown Initiative GmbH</a></p>}
          linkToProject={"https://www.carbdown.net"}
          />
      </div>
    </section>
  );
}

export default Projects;
