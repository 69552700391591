import React, { useState, useEffect, createContext } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  // State for dark mode.
  const [isDarkMode, setIsDarkMode] = useState(false);

  // On init check if the browser is in dark mode.
  useEffect(() => {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      setIsDarkMode(true)
    } else {
      setIsDarkMode(false)
    }
  }, [])

  // Update the document's class list with dark mode toggle
  useEffect(() => {
    if (isDarkMode === true) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  return (
    <ThemeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
      <div className={"text-textcolor-dark dark:text-textcolor-light bg-backgroundcolor-light dark:bg-backgroundcolor-dark"}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};
