import React from 'react';
import ScrollTop from './ScrollTop';
import resumeImage from '../assets/resumeImage.png'


const Resume = () => {
  return (
    <section id="Resume" className="py-6 px-4 self-center w-[80vw] text-center sm:text-left">
      <ScrollTop />
      <div className="container mx-auto flex flex-col sm:flex-row">
        <img src={resumeImage} alt="Keagan Holmes" className="w-32 h-32 m-auto sm:left-4 sm:ml-8 sm:mr-28 rounded-full"/>
        <div>
          <h2 className="text-2xl font-bold mb-4">Resume</h2>
          <p>I graduated from Clairemont High School in 2020 and finshed my Bachelor's at Jacobs University Bremen in 2023.</p>
          <br/>
          <p>I have a few professional experiences, including two teaching assistant positions and a data engineering internship which I continued as a working student in the following semester.
             I learned a lot from these positions, including skills in team work, remote work, and software engineering.
          </p>
          <br/>
        </div>
      </div>

      <div className="container mx-auto flex flex-col sm:flex-row">
        <div className="w-32 min-w-[128px] m-auto sm:left-4 sm:ml-8 sm:mr-28" />
        <div>
          <p>I even learned how to work with APIs - check out this embedded Spotify playlist that I am adding specifically to support this claim and not to drop my mixtape:</p>
          <iframe title={"Check out the Playlist I Share With People"} className="w-[100%] max-w-[30rem] mt-10" src="https://open.spotify.com/embed/playlist/2vLmLKbIwVDoMnLf1icN8o?utm_source=generator" height="380" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
        </div>
      </div>
    </section>
  );
}

export default Resume;
